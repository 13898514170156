import { Navbar, Alert } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import styles from "../../styles/Layout.module.scss"
//import "../../styles/footer.css"
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralContext } from "../../contexts/GeneralContext";
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function Layout({ children }) {
    const baseUrl = "";
    const isMobile = useMediaQuery('(max-width: 900px)');
    const generalDetails = useContext(GeneralContext);
    const [dev, setDev] = useState(false);
    const maintenanceBlock = useRef() //Recupérer l'élément parent si en maintenance

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("dev")) === true && generalDetails.devMode === true) {
            setDev(true);
        }

        if (generalDetails?.maintenance &&
            !dev &&
            maintenanceBlock?.current?.childNodes?.length > 0
        ) {
            const blockChild = maintenanceBlock.current.childNodes[1].className //Récupérer la class de l'enfant si en maintenance

            //console.log(blockChild === styles.maintenanceContainer)
            if (
                blockChild === styles.maintenanceContainer &&
                maintenanceBlock?.current?.childNodes?.length > 2
            ) {
                maintenanceBlock.current.style.backgroundImage = 'none'
                maintenanceBlock.current.childNodes[0].style.display = 'none'
                maintenanceBlock.current.childNodes[2].style.display = 'none'
            }
        }
    }, [dev, generalDetails]);

    return (
        <>
            {
                generalDetails.showSpinner &&
                <div className={`${styles.spinnerContainer} ${process.env.REACT_APP_ENVIRONNEMENT !== "PROD" ? styles.debug : ''}`}>
                    <div className={styles.spinnerPulse}>
                        <img src="/images/1_Illustration_Pastille_Aneo.svg" alt="Illustration_Pastille_Aneo" />
                    </div>
                </div>
            }
            <div className={`${styles.alertContainer}`}>
                <Alert show={generalDetails.alert.show} transition={true} variant={generalDetails.alert.variant} onClose={() => generalDetails.setAlerteShow(false)} dismissible={generalDetails.alert.dismissible}>
                    <Alert.Heading>{generalDetails.alert.header}</Alert.Heading>
                    <p>{generalDetails.alert.message}</p>
                </Alert>
            </div>
            {
                <main ref={maintenanceBlock} className={`${styles.main} ${generalDetails.showSpinner && process.env.REACT_APP_ENVIRONNEMENT !== "PROD" ? styles.containerSpinnerDebug : ''}`} >
                    {generalDetails.showMenu &&
                        <header>
                            <Navbar expand="lg" className={styles.nav}>
                                <Navbar.Brand href={`https://nuvee.fr`} className={styles.logo}>
                                    <img
                                        src="/images/Nuvee.png"
                                        alt="Logo"
                                    />
                                </Navbar.Brand>
                            </Navbar>
                        </header>
                    }

                    {
                        generalDetails.maintenance && !dev &&

                        <div className={styles.maintenanceContainer}>

                            <div className={styles.titleMaintenance}>
                                <a href='https://nuvee.fr'>
                                    {isMobile ?
                                        <img src={`${baseUrl}/images/LOGO_NUVEE_FOOTER.png`} alt='maintenance' /> :
                                        <img src={`${baseUrl}/images/Nuvee.png`} alt='maintenance' />
                                    }
                                </a>
                                <h1><span>Nuvee</span> revient dans quelques minutes, le temps d&apos;une <span>mise à jour</span> !</h1>

                                <div className={styles.networkMaintenance}>
                                    <h2>Pour vous faire patienter un peu, <span>suivez-nous sur les réseaux sociaux !</span></h2>
                                    <div><a href='https://fr.linkedin.com/company/nuvee-epsd' target='_blank' rel="linkedin noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></div>
                                    <div><a href='https://www.instagram.com/_nuvee/' target='_blank' rel="instagram noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></div>
                                    <div><a href='https://www.facebook.com/EMNo_line-410053662921922' target='_blank' rel="facebook noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></div>
                                </div>
                            </div>
                            <div className={styles.imgMaintenance}>
                                {isMobile ?
                                    <img src={`${baseUrl}/images/maintenance-mobile.png`} alt='Logo maintenance' /> :
                                    <img src={`${baseUrl}/images/maintenance.svg`} alt='Logo maintenance' />}
                            </div>

                        </div>

                    }
                    {
                        (!generalDetails.maintenance || dev) &&
                        <Outlet />
                    }

                    {generalDetails.showFooter &&
                        <footer className={styles.footerDiv}>
                            <div className={styles.footerContainer}>
                                <div className={styles.leftContainer}>
                                    <ul>
                                    <li><a target="_blank" href="https://nuvee.fr/contactez-nous"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 512 512">
                                        <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                                        </svg>Contactez-nous</a></li>

                                        <li><a target="_blank" href="https://nuvee.fr/informations-legales/"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 384 512">
                                        <path d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z"></path>
                                        </svg>Informations légales</a></li>

                                        <li><a target="_blank" href="https://nuvee.fr/qui-sommes-nous/"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 640 512">
                                        <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
                                        </svg>Qui sommes-nous ?</a></li>
                                    </ul>
                                </div>
                                <div className={styles.middleContainer}>
                                    <a href="https://nuvee.fr" ><img width="100%" max-width="100%" height="150px" src="https://nuvee.fr/wp-content/uploads/2024/09/Fichier-2.svg" className={styles.logoImage} alt="Logo" /></a>

                                </div>
                                <div className={styles.rightContainer}>
                                    <>
                                        <a target="_blank" rel="twitter noreferrer" href="https://fr.linkedin.com/company/nuvee-epsd">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 448 512">
                                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                                            </svg>
                                        </a>
                                        <a target="_blank" rel="twitter noreferrer" href="https://www.instagram.com/_nuvee/">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                            <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                                        </svg>
                                        </a>
                                        <a target="_blank" rel="facebook noreferrer" href="https://www.facebook.com/EMNo_line-410053662921922">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                            <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                                        </svg>
                                        </a>
                                    </>
                                </div>
                            </div>
                        </footer>
                    }
                    <div className={styles.copyright}>
                        Copyright © {new Date().getFullYear()} – Tous droits réservés EPSD 
                    </div>
                </main>
            }
        </>
    )
}